<template>
  <div>
    <topbar />
    <main-table />
  </div>
</template>

<script>
import MainTable from '../components/financeLogs/FinanceLogsMainTable.vue';
import Topbar from '../components/financeLogs/FinanceLogsTopbar.vue';

export default {
  name: 'FinanceLogs',
  components: { MainTable, Topbar },
};
</script>

<style scoped>

</style>
