<template>
  <div class="d-flex align-center justify-space-between mb-2">
    <div
      class="d-flex align-center"
    >
      <!-- ПОИСК -->
      <v-select
        :items="levels"
        label="Level"
        @change="setLevel"
      />
      <v-select
        :items="providers"
        label="Provider"
        :v-model="form.provider"
        @change="setProvider"
      />
      <v-spacer />
      <v-text-field
        :value="form.invoiceId"
        :v-model="form.invoiceId"
        label="Инвойс"
        dense
        solo
        single-line
        hide-details
        clearable
        @input="setInvoice"
      />
      <v-spacer />
      <v-text-field
        :value="form.teamName"
        :v-model="form.teamName"
        label="Email"
        dense
        solo
        single-line
        hide-details
        clearable
        @input="setTeamName"
      />
      <v-spacer />
      <v-text-field
        :value="form.subscriptionId"
        :v-model="form.subscriptionId"
        label="Rec.Subscription ID"
        dense
        solo
        single-line
        hide-details
        clearable
        @input="setSubscription"
      />
      <v-spacer />
      <v-text-field
        :value="form.message"
        :v-model="form.message"
        label="Message"
        dense
        solo
        single-line
        hide-details
        clearable
        @input="setMessage"
      />
      <v-spacer />
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.createdAt"
            clearable
            label="от даты"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="form.createdAt"
          @input="menu = false; search();"
        />
      </v-menu>
      <v-spacer />
      <v-text-field
        :value="form.username"
        :v-model="form.username"
        label="Что ищем?"
        dense
        solo
        single-line
        hide-details
        clearable
        clear-icon="mdi-close"
        :spellcheck="false"
        @input="setUsername"
      >
        <template #prepend-inner>
          <v-icon size="18">
            mdi-magnify
          </v-icon>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FinanceLogsTopbar',
  data() {
    return {
      loadingRefreshButton: false,
      menu: false,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'financeLogs/loading',
      profile: 'main/profile',
      levels: 'financeLogs/levels',
      providers: 'financeLogs/providers',
      pagination: 'financeLogs/pagination',
      form: 'financeLogs/form',
    }),
  },

  methods: {
    setLevel(level) {
      this.form.level = level;
      this.search();
    },
    setProvider(provider) {
      this.form.provider = provider;
      this.search();
    },
    setUsername(username) {
      this.form.username = username;
      this.search();
    },
    setInvoice(id) {
      this.form.invoiceId = id;
      this.search();
    },
    setTeam(teamId) {
      this.form.teamId = teamId;
      this.search();
    },
    setTeamName(teamName) {
      this.form.teamName = teamName;
      this.search();
    },
    setSubscription(id) {
      this.form.subscriptionId = id;
      this.search();
    },
    setMessage(message) {
      this.form.message = message;
      this.search();
    },
    setCreatedAt(date) {
      this.form.createdAt = date;
      this.search();
    },
    search() {
      setTimeout(async () => {
        await this.$store.dispatch('financeLogs/loadFinanceLogs', this.form);
      }, 500);
    },
  },
};
</script>
